/* eslint-disable @typescript-eslint/no-redeclare */
import * as t from 'io-ts';
import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import httpClient, {
  DeletendpointInjector,
  GetEndpointInjector,
  PostEndpointInjector
} from '../api/httpClient';
import { Options, Paginated, buildPagingOptions } from './paging';
import { handleErrorResponse } from '../utils/utils';
import { getAccessToken, isValid } from './utils';

export const MachineMinProps = t.type({
  machine_id: t.string
});

export const MachineBase = t.exact(
  t.intersection([
    t.type({
      name: t.string,
      stuck: t.string,
      cylinder: t.number,
      cylinder_extra: t.number,
      troquel_num: t.number
    }),
    t.partial({
      clise_num: t.number
    })
  ])
);

export type MachineBase = t.TypeOf<typeof MachineBase>;
export type MachineMinProps = t.TypeOf<typeof MachineMinProps>;
export type Machine = MachineMinProps & MachineBase;

export const isMachineValid = isValid(MachineBase);

export const postMachine = createAsyncThunk(
  'machines-p',
  async (item: PostEndpointInjector<MachineBase>, { dispatch }) => {
    try {
      const accessToken = getAccessToken();
      const response = await httpClient.post(`/machines`, item.body, {
        headers: { access_token: accessToken }
      });

      return response.data;
    } catch (error) {
      const errorResponse = await handleErrorResponse({ error, dispatch, navigate: item.navigate });
      return errorResponse;
    }
  }
);

export const deleteMachine = createAsyncThunk(
  'machines-d',
  async (item: DeletendpointInjector, { dispatch }) => {
    try {
      const accessToken = getAccessToken();
      const response = await httpClient.delete(`/machines/${item.id}`, {
        headers: { access_token: accessToken }
      });
      return response.data;
    } catch (error) {
      const err = handleErrorResponse({ error, dispatch, navigate: item.navigate });
      console.log(err);
      return err;
    }
  }
);

export const getMachines = createAsyncThunk(
  'machines',
  async (item: GetEndpointInjector<Options>, { dispatch }) => {
    try {
      const accessToken = getAccessToken();
      const response = await httpClient.get(`/machines?${buildPagingOptions(item.options)}`, {
        headers: { access_token: accessToken }
      });
      return response.data;
    } catch (error) {
      const err = handleErrorResponse({ error, dispatch, navigate: item.navigate });
      return err;
    }
  }
);

export const searchMachine = createAsyncThunk(
  'search-machine',
  async (deps: GetEndpointInjector<{ word: string }>, { dispatch }) => {
    try {
      const accessToken = getAccessToken();
      const response = await httpClient.get(
        `/search/machines?search_word=${deps.options?.word ?? ''}`,
        {
          headers: { access_token: accessToken }
        }
      );
      return response.data;
    } catch (error) {
      const err = handleErrorResponse({ error, dispatch, navigate: deps.navigate });
      return err;
    }
  }
);

type ErrorResponse = {
  message: string;
};

type MachineApiState = {
  machines?: Paginated<Machine> | null;
  page: number;
  limit: number;
  count: number;
  status: 'idle' | 'loading' | 'failed';
  error: string | null;
};

const initialState: MachineApiState = {
  machines: undefined,
  status: 'idle',
  page: 1,
  limit: 10,
  count: 0,
  error: null
};

const machineSlice = createSlice({
  name: 'machine',
  initialState,
  reducers: {
    setPage(state, action: PayloadAction<number>) {
      state.page = action.payload;
      state.machines = undefined;
    },
    setLimit(state, action: PayloadAction<number>) {
      state.limit = action.payload;
      state.page = 1;
      state.machines = undefined;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getMachines.pending, (state) => {
        state.status = 'loading';
        state.error = null;
      })
      .addCase(getMachines.fulfilled, (state, action: PayloadAction<Paginated<Machine>>) => {
        state.status = 'idle';
        state.machines = action.payload;
        state.count = action.payload.count;
      })
      .addCase(getMachines.rejected, (state, action) => {
        state.status = 'failed';

        if (action.payload) {
          state.error = (action.payload as ErrorResponse).message || 'Login failed';
        } else {
          state.error = action.error.message || 'Login failed';
        }
      })
      .addCase(searchMachine.pending, (state) => {
        state.status = 'loading';
        state.error = null;
      })
      .addCase(searchMachine.fulfilled, (state, action: PayloadAction<Paginated<Machine>>) => {
        state.status = 'idle';
        state.machines = action.payload;
        state.count = action.payload.count;
      })
      .addCase(searchMachine.rejected, (state, action) => {
        state.status = 'failed';

        if (action.payload) {
          state.error = (action.payload as ErrorResponse).message || 'Login failed';
        } else {
          state.error = action.error.message || 'Login failed';
        }
      })
      .addCase(postMachine.pending, (state) => {
        state.status = 'loading';
        state.error = null;
      })
      .addCase(postMachine.fulfilled, (state, action) => {
        state.status = 'idle';
        state.machines = undefined;
      })
      .addCase(postMachine.rejected, (state, action) => {
        state.status = 'failed';
        if (action.payload) {
          state.error = (action.payload as ErrorResponse).message || 'Login failed';
        } else {
          state.error = action.error.message || 'Login failed';
        }
      })
      .addCase(deleteMachine.pending, (state) => {
        state.status = 'loading';
        state.error = null;
      })
      .addCase(deleteMachine.fulfilled, (state, action) => {
        state.status = 'idle';
        state.machines = undefined;
      })
      .addCase(deleteMachine.rejected, (state, action) => {
        state.status = 'failed';
        if (action.payload) {
          state.error = (action.payload as ErrorResponse).message || 'Login failed';
        } else {
          state.error = action.error.message || 'Login failed';
        }
      });
  }
});

export const { setPage } = machineSlice.actions;
export const { setLimit } = machineSlice.actions;
export default machineSlice.reducer;
