import * as t from 'io-ts';
import * as E from 'fp-ts/Either';
import { pipe } from 'fp-ts/function';
import { logout } from './authSlice';

export const isValid =
  <A>(codec: t.Decoder<unknown, A>) =>
  (value: unknown): value is A =>
    pipe(
      value,
      codec.decode,
      E.map(() => true),
      E.getOrElse(() => false)
    );

export const getAccessToken = () => {
  const val = sessionStorage.getItem('userInfo');

  if (val === undefined || val === null) {
    logout();
  }

  const jsonVal = JSON.parse(val!);

  return jsonVal.access_token;
};
