import { Box, TextField, Button, Divider, MenuItem } from '@mui/material';
import { ChangeEvent, useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../hooks/redux-hooks';
import { NotificationType, showNotification } from '../slices/notificationSlice';
import {
  ProductGet,
  ProductAttributes,
  getProduct,
  postProduct,
  putProduct,
  resetProductState,
  setLimit,
  setPage
} from '../slices/productSlice';
import { getMachines } from '../slices/machineSlice';
import { useNavigate } from 'react-router-dom';
import {
  getValueWithCorrectType,
  isErrorStateValidationErrorType,
  updatePropertyByPath
} from '../utils/utils';
import { useParams } from 'react-router-dom';
import { getClients, searchClients } from '../slices/clientSlice';
import FilterableSelect from './library/FilterableSelect';

const Product = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { productId } = useParams<{ productId: string }>();
  const emptyProduct: ProductAttributes = {
    name: '',
    material: '',
    color: '',
    mother_coil_width: 0,
    print_type: '',
    art_format_x1: 0,
    art_format_x2: 0,
    type: '',
    variation: 0,
    amount_of_colors: 0,
    machine: {
      name: '',
      // stuck: '',
      // cylinder: 0,
      // cylinder_extra: 0,
      // troquel_num: 0,
      // clise_num: 0,
      machine_id: '',
      module: {
        color: '',
        color_num: '',
        color_extra: ''
      }
    },
    labels_per_roll: 0,
    labels_per_full_box: 0,
    size: {
      width: 0,
      height: 0
    },
    position: 0,
    client_id: ''
  };

  const emptyProductErrorMessageState = {
    name: { error: false, message: undefined },
    material: { error: false, message: undefined },
    color: { error: false, message: undefined },
    mother_coil_width: { error: false, message: undefined },
    print_type: { error: false, message: undefined },
    art_format_x1: { error: false, message: undefined },
    art_format_x2: { error: false, message: undefined },
    type: { error: false, message: undefined },
    variation: { error: false, message: undefined },
    amount_of_colors: { error: false, message: undefined },
    machine: {
      name: { error: false, message: undefined },
      // stuck: { error: false, message: undefined },
      // cylinder: { error: false, message: undefined },
      // cylinder_extra: { error: false, message: undefined },
      // troquel_num: { error: false, message: undefined },
      // clise_num: { error: false, message: undefined },
      machine_id: { error: false, message: undefined },
      module: {
        color: { error: false, message: undefined },
        color_num: { error: false, message: undefined },
        color_extra: { error: false, message: undefined }
      }
    },
    labels_per_roll: { error: false, message: undefined },
    labels_per_full_box: { error: false, message: undefined },
    size: {
      width: { error: false, message: undefined },
      height: { error: false, message: undefined }
    },
    position: { error: false, message: undefined },
    client_id: { error: false, message: undefined }
  };

  type EmptyProductErrorMessageState = typeof emptyProductErrorMessageState;

  const [newProduct, setNewProduct] = useState(emptyProduct);
  const [productErrorState, setProductErrorstate] = useState(emptyProductErrorMessageState);

  const machines = useAppSelector((state) => state.machine.machines);
  const { clients } = useAppSelector((state) => state.client);

  const handleProductCreation = async () => {
    if (newProduct.name) {
      try {
        const response = await dispatch(postProduct({ body: newProduct, navigate })).unwrap();

        if (response.type !== 'ERROR') {
          setPage(1);
          setLimit(10);
          setNewProduct(emptyProduct);
          navigate('/products');
        } else {
          setProductErrorstate(emptyProductErrorMessageState);
          if (isErrorStateValidationErrorType(response)) {
            for (let i = 0; i < response.data.data.length; i++) {
              setProductErrorstate((prevState) => {
                console.log(response.data.data[i]);

                const res = updatePropertyByPath(prevState, response.data.data[i].key, {
                  error: true,
                  message: response.data.data[i].message
                }) as EmptyProductErrorMessageState;
                console.log(res);
                return res;
              });
            }
          }
        }
      } catch (e) {
        console.error(e);
      }
    } else {
      dispatch(
        showNotification({
          message: 'Please provide value',
          type: NotificationType.Error
        })
      );
    }
  };

  useEffect(() => {
    if (productId) {
      dispatch(getProduct({ id: productId, navigate })).then((prod) => {
        handleNewProductUpdateFromOneResponse(prod.payload);
      });
    } else {
      setNewProduct({
        name: '',
        material: '',
        color: '',
        mother_coil_width: 0,
        print_type: '',
        art_format_x1: 0,
        art_format_x2: 0,
        variation: 0,
        type: '',
        amount_of_colors: 0,
        machine: {
          name: '',
          // stuck: '',
          // cylinder: 0,
          // cylinder_extra: 0,
          // troquel_num: 0,
          // clise_num: 0,
          machine_id: '',
          module: {
            color: '',
            color_num: '',
            color_extra: ''
          }
        },
        labels_per_roll: 0,
        labels_per_full_box: 0,
        size: {
          width: 0,
          height: 0
        },
        position: 0,
        client_id: ''
      });
    }

    dispatch(getClients({ options: { paging: { page: 1, limit: 10 } }, navigate }));
    dispatch(getMachines({ navigate }));
  }, [productId, dispatch, navigate]);

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    console.log(name);
    console.log(value);

    const paths = name.split('.');
    if (paths.length === 1) {
      setNewProduct((prevState) => ({
        ...prevState,
        [name]: getValueWithCorrectType(value)
      }));
    } else if (paths.length === 2 && paths[0] === 'machine') {
      setNewProduct((prevState) => ({
        ...prevState,
        machine: {
          ...prevState.machine,
          [paths[1]]: getValueWithCorrectType(value)
        }
      }));
    } else if (paths.length === 2 && paths[0] === 'size') {
      setNewProduct((prevState) => ({
        ...prevState,
        size: {
          ...prevState.size,
          [paths[1]]: getValueWithCorrectType(value)
        }
      }));
    } else if (paths.length === 3 && paths[0] === 'machine' && paths[1] === 'module') {
      setNewProduct((prevState) => ({
        ...prevState,
        machine: {
          ...prevState.machine,
          module: {
            ...prevState.machine.module,
            [paths[2]]: getValueWithCorrectType(value)
          }
        }
      }));
    }
  };

  const handleMachineNameChangeSelect = (e: ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    const selectedMachine = machines?.items.find((m) => m.name === value);
    if (selectedMachine) {
      setNewProduct({
        ...newProduct,
        machine: {
          ...newProduct.machine,
          name: selectedMachine.name,
          // stuck: selectedMachine.stuck,
          // cylinder: selectedMachine.cylinder,
          // cylinder_extra: selectedMachine.cylinder_extra,
          // troquel_num: selectedMachine.troquel_num,
          // clise_num: selectedMachine.clise_num,
          machine_id: selectedMachine.machine_id
        }
      });
    }
  };

  const handleNewProductUpdateFromOneResponse = (product: ProductGet) => {
    const productToUpdate: ProductAttributes = {
      ...product.attributes,
      variation: product.variation,
      type: product.type,
      name: product.name,
      client_id: product.client?.client_id,
      machine: {
        machine_id: product.machine.machine_id,
        name: product.machine.name,
        module: product.attributes.machine.module
      }
    };
    setNewProduct(productToUpdate);
  };

  const handleProductUpdating = async () => {
    if (newProduct.name && productId !== undefined) {
      try {
        console.log(newProduct);
        const response = await dispatch(
          putProduct({ body: newProduct, id: productId, navigate })
        ).unwrap();

        if (response.type !== 'ERROR') {
          console.log('Updated');
          setPage(1);
          setLimit(10);
          dispatch(resetProductState());
          setNewProduct(emptyProduct);
          navigate('/products');
        } else {
          setProductErrorstate(emptyProductErrorMessageState);
          if (isErrorStateValidationErrorType(response)) {
            for (let i = 0; i < response.data.data.length; i++) {
              setProductErrorstate((prevState) => {
                console.log(response.data.data[i]);

                const res = updatePropertyByPath(prevState, response.data.data[i].key, {
                  error: true,
                  message: response.data.data[i].message
                }) as EmptyProductErrorMessageState;
                console.log(res);
                return res;
              });
            }
          }
        }
      } catch (e) {
        console.error(e);
      }
    } else {
      dispatch(
        showNotification({
          message: 'Please provide value',
          type: NotificationType.Error
        })
      );
    }
  };

  const cancelUpdate = () => {
    dispatch(resetProductState());
    setNewProduct(emptyProduct);
    navigate('/products');
  };

  const handleUpdateOrCreate = async () => {
    if (productId === undefined) {
      return await handleProductCreation();
    } else {
      return await handleProductUpdating();
    }
  };

  const handleSearchChange = (value: string) => {
    if (value === '') {
      dispatch(getClients({ options: { paging: { page: 1, limit: 10 } }, navigate }));
    } else {
      dispatch(searchClients({ options: { word: value }, navigate }));
    }
  };

  return (
    <Box
      sx={{
        maxWidth: 1100,
        minWidth: 300,
        margin: '0 auto',
        padding: 2,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center'
      }}>
      <Box
        component="form"
        autoComplete="off"
        sx={{
          '& .MuiTextField-root': { m: 1, width: '25ch' },
          '& .MuiFormControl-root': { m: 1, width: '25ch' }
        }}>
        <div>
          {productId === undefined ? (
            <div>
              <Divider sx={{ marginTop: 2, marginBottom: 2 }} textAlign="left">
                Seleccionar Cliente
              </Divider>
              {FilterableSelect({
                options: clients
                  ? clients.map((c) => ({ id: c.client_id, label: `${c.name} (${c.code})` }))
                  : [],
                label: 'Buscar cliente',
                textInputOnChange: handleSearchChange,
                selectOnChange: (
                  value: {
                    id: string;
                    label: string;
                  } | null
                ) => {
                  if (value) {
                    setNewProduct({
                      ...newProduct,
                      client_id: value.id
                    });
                  }
                }
              })}
            </div>
          ) : (
            <></>
          )}
        </div>

        <Divider sx={{ marginTop: 2, marginBottom: 2 }} textAlign="left">
          Detalle del producto
        </Divider>
        <div>
          <TextField
            select
            margin="normal"
            required
            id="type"
            label="Tipo"
            name="type"
            type="text"
            value={newProduct.type}
            onChange={handleChange}
            sx={{ flex: 1 }}>
            <MenuItem key={'POLIETILENO'} value={'POLIETILENO'}>
              POLIETILENO
            </MenuItem>
            <MenuItem key={'AUTOADHESIVAS'} value={'AUTOADHESIVAS'}>
              AUTOADHESIVAS
            </MenuItem>
          </TextField>

          <TextField
            margin="normal"
            required
            fullWidth
            id="name"
            label="Nombre"
            name="name"
            value={newProduct.name}
            onChange={handleChange}
            helperText={productErrorState.name.message}
            error={productErrorState.name.error}
          />
          <TextField
            margin="normal"
            required
            fullWidth
            id="material"
            label="Material"
            name="material"
            value={newProduct.material}
            onChange={handleChange}
            helperText={productErrorState.material.message}
            error={productErrorState.material.error}
          />

          <TextField
            margin="normal"
            required
            fullWidth
            id="color"
            label="Color"
            name="color"
            value={newProduct.color}
            onChange={handleChange}
            helperText={productErrorState.color.message}
            error={productErrorState.color.error}
          />
        </div>
        <div>
          <TextField
            margin="normal"
            required
            fullWidth
            id="mother_coil_width"
            label="Ancho de la bobina madre"
            name="mother_coil_width"
            type="number"
            value={newProduct.mother_coil_width === 0 ? '' : +newProduct.mother_coil_width}
            onChange={handleChange}
            helperText={productErrorState.mother_coil_width.message}
            error={productErrorState.mother_coil_width.error}
          />

          <TextField
            margin="normal"
            required
            fullWidth
            id="print_type"
            label="Impresion"
            name="print_type"
            type="string"
            value={newProduct.print_type}
            onChange={handleChange}
            helperText={productErrorState.print_type.message}
            error={productErrorState.print_type.error}
          />

          <TextField
            margin="normal"
            required
            fullWidth
            id="amount_of_colors"
            label="N. Colores"
            name="amount_of_colors"
            type="number"
            value={newProduct.amount_of_colors === 0 ? '' : newProduct.amount_of_colors}
            onChange={handleChange}
            helperText={productErrorState.amount_of_colors.message}
            error={productErrorState.amount_of_colors.error}
          />

          <TextField
            margin="normal"
            required
            fullWidth
            id="variation"
            label="Variacion"
            name="variation"
            type="number"
            value={newProduct.variation === 0 ? '' : newProduct.variation}
            onChange={handleChange}
            helperText={productErrorState.variation.message}
            error={productErrorState.variation.error}
          />
        </div>
        <Divider sx={{ marginTop: 2, marginBottom: 2 }} textAlign="left">
          Formato Arte
        </Divider>
        <div>
          <TextField
            margin="normal"
            required
            fullWidth
            id="art_format_x1"
            label="X"
            name="art_format_x1"
            type="number"
            value={newProduct.art_format_x1 === 0 ? '' : newProduct.art_format_x1}
            onChange={handleChange}
            helperText={productErrorState.art_format_x1.message}
            error={productErrorState.art_format_x1.error}
          />

          <TextField
            margin="normal"
            required
            fullWidth
            id="art_format_x2"
            label="Y"
            name="art_format_x2"
            type="number"
            value={newProduct.art_format_x2 === 0 ? '' : newProduct.art_format_x2}
            onChange={handleChange}
            helperText={productErrorState.art_format_x2.message}
            error={productErrorState.art_format_x2.error}
          />
        </div>
        <Divider sx={{ marginTop: 2, marginBottom: 2 }} textAlign="left">
          Maquina
        </Divider>
        <div>
          <TextField
            select
            margin="normal"
            required
            id="machine.name"
            label="Maquina"
            name="machine.name"
            type="text"
            value={newProduct.machine.name}
            onChange={handleMachineNameChangeSelect}
            sx={{ flex: 1 }}>
            {(machines ? machines.items : []).map((val) => (
              <MenuItem key={val.machine_id} value={val.name}>
                {val.name}
              </MenuItem>
            ))}
          </TextField>
          {/* <FormControl fullWidth margin="normal" required sx={{ m: 1, width: '25ch' }}>
            <InputLabel id="label-maquina">Maquina</InputLabel>
            <Select
              labelId="label-maquina"
              label="Maquina"
              id="machine.name"
              name="machine.name"
              value={newProduct.machine.name}
              onChange={handleMachineNameChangeSelect}>
              {(machines ? machines.items : []).map((val) => (
                <MenuItem key={val.machine_id} value={val.name}>
                  {val.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl> */}

          {/* <TextField
                      margin="normal"
                      required
                      fullWidth
                      id="machine.stuck"
                      label="Pegado"
                      name="machine.stuck"
                      type="string"
                      value={newProduct.machine.stuck}
                      onChange={handleChange}
                      helperText={productErrorState.machine.stuck.message}
                      error={productErrorState.machine.stuck.error}
                    />

                    <TextField
                      margin="normal"
                      required
                      fullWidth
                      id="machine.cylinder"
                      label="Cilindro"
                      name="machine.cylinder"
                      type="number"
                      value={newProduct.machine.cylinder === 0 ? '' : newProduct.machine.cylinder}
                      onChange={handleChange}
                      helperText={productErrorState.machine.cylinder.message}
                      error={productErrorState.machine.cylinder.error}
                    />

                    <TextField
                      margin="normal"
                      required
                      fullWidth
                      id="machine.name"
                      label="Cilindro (extra)"
                      name="machine.cylinder_extra"
                      type="number"
                      value={
                        newProduct.machine.cylinder_extra === 0
                          ? ''
                          : newProduct.machine.cylinder_extra
                      }
                      onChange={handleChange}
                      helperText={productErrorState.machine.cylinder_extra.message}
                      error={productErrorState.machine.cylinder_extra.error}
                    />
                  </div>

                  <div>
                    <TextField
                      margin="normal"
                      required
                      fullWidth
                      id="machine.troquel_num"
                      label="Troquel"
                      name="machine.troquel_num"
                      type="number"
                      value={
                        newProduct.machine.troquel_num === 0 ? '' : newProduct.machine.troquel_num
                      }
                      onChange={handleChange}
                      helperText={productErrorState.machine.troquel_num.message}
                      error={productErrorState.machine.troquel_num.error}
                    />

                    <TextField
                      margin="normal"
                      required
                      fullWidth
                      id="machine.clise_num"
                      label="Clise"
                      name="machine.clise_num"
                      type="number"
                      value={newProduct.machine.clise_num === 0 ? '' : newProduct.machine.clise_num}
                      onChange={handleChange}
                      helperText={productErrorState.machine.clise_num.message}
                      error={productErrorState.machine.clise_num.error}
                    /> */}

          <TextField
            margin="normal"
            required
            fullWidth
            id="machine.module.color"
            label="Color"
            name="machine.module.color"
            type="string"
            value={newProduct.machine.module.color}
            onChange={handleChange}
            helperText={productErrorState.machine.module.color.message}
            error={productErrorState.machine.module.color.error}
          />

          <TextField
            margin="normal"
            required
            fullWidth
            id="machine.module.color_num"
            label="Color Num"
            name="machine.module.color_num"
            type="string"
            value={newProduct.machine.module.color_num}
            onChange={handleChange}
            helperText={productErrorState.machine.module.color_num.message}
            error={productErrorState.machine.module.color_num.error}
          />
        </div>
        <div>
          <TextField
            margin="normal"
            required
            fullWidth
            id="machine.module.color_extra"
            label="Color (extra)"
            name="machine.module.color_extra"
            type="string"
            value={newProduct.machine.module.color_extra}
            onChange={handleChange}
            helperText={productErrorState.machine.module.color_extra.message}
            error={productErrorState.machine.module.color_extra.error}
          />
        </div>

        <Divider sx={{ marginTop: 2, marginBottom: 2 }} textAlign="left">
          Etiquetas por rollo
        </Divider>

        <div>
          <TextField
            margin="normal"
            required
            fullWidth
            id="labels_per_roll"
            label="Etiquetas por rollo"
            name="labels_per_roll"
            type="number"
            value={newProduct.labels_per_roll === 0 ? '' : newProduct.labels_per_roll}
            onChange={handleChange}
            helperText={productErrorState.labels_per_roll.message}
            error={productErrorState.labels_per_roll.error}
          />

          <TextField
            margin="normal"
            required
            fullWidth
            id="labels_per_full_box"
            label="Etiquetas por caja completa"
            name="labels_per_full_box"
            type="number"
            value={newProduct.labels_per_full_box === 0 ? '' : newProduct.labels_per_full_box}
            onChange={handleChange}
            helperText={productErrorState.labels_per_full_box.message}
            error={productErrorState.labels_per_full_box.error}
          />
        </div>

        <Divider sx={{ marginTop: 2, marginBottom: 2 }} textAlign="left">
          Medida
        </Divider>

        <div>
          <TextField
            margin="normal"
            required
            fullWidth
            id="size_width"
            label="Ancho"
            name="size.width"
            type="number"
            value={newProduct.size.width === 0 ? '' : newProduct.size.width}
            onChange={handleChange}
            helperText={productErrorState.size.width.message}
            error={productErrorState.size.width.error}
          />

          <TextField
            margin="normal"
            required
            fullWidth
            id="size_height"
            label="Alto"
            name="size.height"
            type="number"
            value={newProduct.size.height === 0 ? '' : newProduct.size.height}
            onChange={handleChange}
            helperText={productErrorState.size.height.message}
            error={productErrorState.size.height.error}
          />

          <TextField
            margin="normal"
            required
            fullWidth
            id="position"
            label="Posicion"
            name="position"
            type="number"
            value={newProduct.position === 0 ? '' : newProduct.position}
            onChange={handleChange}
            helperText={productErrorState.position.message}
            error={productErrorState.position.error}
          />
        </div>

        <Box sx={{ display: 'flex', gap: 2 }}>
          {/* {productId !== undefined ? ( */}
          <Button fullWidth variant="contained" sx={{ mt: 3, mb: 2 }} onClick={cancelUpdate}>
            Cancelar
          </Button>
          {/* ) : null} */}

          <Button
            fullWidth
            variant="contained"
            sx={{ mt: 3, mb: 2 }}
            onClick={handleUpdateOrCreate}>
            {productId !== undefined ? 'EDITAR' : 'CREAR'}
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

export default Product;
